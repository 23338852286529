import { useKYC } from "composables/use-kyc.js";
import _ from "underscore";
import { authStore } from "@/store/auth";
import { computed } from "vue";
import { baseUrl } from "config/services.js";
import { useToaster } from "composables/use-toaster.js";
import { utilitiesStore } from "store/utilities.js";
import { useRouter } from "vue-router";
import { bonusStore } from "@/store/bonus";
import { useFormatter } from "composables/use-formatter.js";
const { money } = useFormatter();

export const usePromotion = () => {
     const router = useRouter();

     const useAuthStore = authStore();
     const useUtilitiesStore = utilitiesStore();
     const useBonusStore = bonusStore();

     const isUser = computed(() => {
          return useAuthStore.user;
     });

     const referral_code = computed(() => {
          return useAuthStore.referral_code;
     });

     const referral_stats = computed(() => {
          return useBonusStore.referral_stats;
     });

     const referralBonus = async () => {
          const el = document.getElementById("promotion-refer-friends-bonus");

          if (el) {
               let kyc = null;
               if (isUser.value) {
                    const { validateEveryAction } = useKYC();
                    kyc = validateEveryAction("referral");
               }

               let showShare = null;
               let textBtn = "";

               let link = "";

               if (kyc?.status == true) {
                    showShare = false;
                    textBtn = kyc.type;
               } else {
                    showShare = true;
                    textBtn = "";
               }

               if (isUser.value) {
                    const filtered = _.find(
                         isUser.value?.meta,
                         function (params) {
                              return params["meta_key"] == "referral_code";
                         }
                    );

                    if (filtered) {
                         const parseFilter = JSON.parse(filtered.meta_value);
                         link = `${baseUrl.api}/signup?referral_code=${parseFilter}`;
                    } else {
                         await useAuthStore.getReferralCode();
                         if (referral_code.value) {
                              link = `${baseUrl.api}/signup?referral_code=${referral_code.value}`;
                         }
                    }
               }

               if (isUser.value && showShare) {
                    const section = document.querySelector(
                         "#referral-auth-share"
                    );

                    section.classList.remove("!hidden");

                    const referralUrl =
                         document.querySelector("#referral-link");

                    referralUrl.value = link;

                    const error = document.querySelectorAll(
                         ".email-referral-error"
                    );

                    for (let index = 0; index < error.length; index++) {
                         error[index].innerHTML = "";
                    }

                    const shareLink = document.querySelectorAll(".share-link");

                    for (let index = 0; index < shareLink.length; index++) {
                         shareLink[index].addEventListener("click", () => {
                              const social =
                                   shareLink[index].getAttribute("data-relay");
                              onShareLink(link, social);
                         });
                    }

                    const copyLink = document.querySelector(
                         "#copy-referral-link"
                    );

                    copyLink.addEventListener("click", () => {
                         onCopyLink(link);
                    });

                    if (isUser.value) {
                         const openStats = document.querySelector(
                              "#open-referral-stats"
                         );

                         openStats.addEventListener("click", () => {
                              onOpenStats();
                         });

                         const closeStats = document.querySelector(
                              "#referral-stats-modal-close"
                         );

                         closeStats.addEventListener("click", () => {
                              onCloseStats();
                         });

                         const timesShared =
                              document.querySelector("#times-shared");
                         const friendsReferred =
                              document.querySelector("#friends-referred");
                         const goldEarn = document.querySelector("#gold-earn");
                         const silverEarn =
                              document.querySelector("#silver-earn");

                         timesShared.innerHTML = referral_stats.value?.viewed
                              ? referral_stats.value?.viewed
                              : "-";
                         friendsReferred.innerHTML = referral_stats.value?.count
                              ? referral_stats.value?.count
                              : "-";
                         goldEarn.innerHTML = money(
                              getTotalReferralGC(
                                   referral_stats.value?.breakdown
                              )
                         );
                         silverEarn.innerHTML = money(
                              getTotalReferralSC(
                                   referral_stats.value?.breakdown
                              )
                         );
                    }
               } else if (isUser.value && !showShare) {
                    const section = document.querySelector(
                         "#referral-auth-not-share"
                    );
                    section.classList.remove("!hidden");

                    const verifyAccount =
                         document.querySelector("#verify-account");
                    verifyAccount.innerHTML = textBtn;

                    verifyAccount.addEventListener("click", () => {
                         onKYC();
                    });
               } else {
                    const section = document.querySelector(
                         "#referral-unauth-not-share"
                    );
                    section.classList.remove("!hidden");

                    const loginShare = document.querySelector("#login-share");

                    loginShare.addEventListener("click", () => {
                         onLoginToShare();
                    });
               }
          }
     };

     const onShareLink = (link, social) => {
          const url = link;
          const text = "Share the fun at Moozi and get FREE SCs coins\n\n";

          switch (social) {
               case "messenger":
                    var messengerUrl =
                         "fb-messenger://share/?text=" +
                         encodeURIComponent(text) +
                         "&link=" +
                         encodeURIComponent(url);
                    window.open(messengerUrl, "_blank");
                    break;
               case "facebook":
                    var fbShareUrl =
                         "https://www.facebook.com/sharer/sharer.php?u=" +
                         encodeURIComponent(url) +
                         "&quote=" +
                         encodeURIComponent(text);
                    window.open(fbShareUrl, "_blank");
                    break;
               case "x":
                    var twitterShareUrl =
                         "https://twitter.com/intent/tweet?text=" +
                         encodeURIComponent(text) +
                         "&url=" +
                         encodeURIComponent(url);
                    window.open(twitterShareUrl, "_blank");
                    break;
               case "sms":
                    var smsUrl =
                         "sms:" +
                         "" +
                         "?body=" +
                         encodeURIComponent(text + " " + url);
                    window.open(smsUrl, "_blank");
                    break;
               case "email":
                    const emailInput =
                         document.querySelector("#email-referral");
                    const error = document.querySelectorAll(
                         ".email-referral-error"
                    );

                    if (emailInput.value == "" || emailInput.value == null) {
                         emailInput.classList.add("error");
                         emailInput.setAttribute("placeholder", "Empty email");
                         for (let index = 0; index < error.length; index++) {
                              error[index].innerHTML = "Empty email";
                         }
                         return;
                    }

                    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    if (!pattern.test(emailInput.value)) {
                         emailInput.classList.add("error");
                         emailInput.setAttribute(
                              "placeholder",
                              "Invalid email"
                         );
                         for (let index = 0; index < error.length; index++) {
                              error[index].innerHTML = "Invalid email";
                         }
                         return;
                    }

                    emailInput.classList.remove("error");
                    emailInput.setAttribute(
                         "placeholder",
                         "Your Friends’s emails"
                    );

                    for (let index = 0; index < error.length; index++) {
                         error[index].innerHTML = "";
                    }

                    var gmailUrl =
                         "mailto:" +
                         emailInput.value +
                         "?subject=" +
                         encodeURIComponent("Moozi") +
                         "&body=" +
                         encodeURIComponent(text + " " + url);
                    window.open(gmailUrl, "_blank");
                    break;
               default:
                    break;
          }
     };

     const onCopyLink = (code) => {
          const { successToast } = useToaster();

          const codeEl = document.createElement("textarea");
          codeEl.value = code;
          document.body.appendChild(codeEl);
          codeEl.select();
          document.execCommand("copy");
          document.body.removeChild(codeEl);
          successToast("Link is copied successfully!", "top-right");
          event.preventDefault();
     };

     const onOpenStats = () => {
          useUtilitiesStore.disableScroll();
          const modal = document.querySelector("#referral-stats-modal");
          const overlay = document.querySelector(
               "#referral-stats-modal-overlay"
          );

          modal.classList.remove("hidden");
          overlay.classList.remove("hidden");
          // eventBus.emit("open:stats");
     };

     const onCloseStats = () => {
          useUtilitiesStore.enableScroll();
          const modal = document.querySelector("#referral-stats-modal");
          const overlay = document.querySelector(
               "#referral-stats-modal-overlay"
          );

          modal.classList.add("hidden");
          overlay.classList.add("hidden");
     };

     const getTotalReferralGC = (breakdown) => {
          return _.reduce(
               breakdown,
               (sum, breakdown) => {
                    return (
                         sum +
                         parseInt(JSON.parse(breakdown.claim_amount).gold_coins)
                    );
               },
               0
          );
     };

     const getTotalReferralSC = (breakdown) => {
          return _.reduce(
               breakdown,
               (sum, breakdown) => {
                    return (
                         sum +
                         parseInt(
                              JSON.parse(breakdown.claim_amount).silver_coins
                         )
                    );
               },
               0
          );
     };

     const onLoginToShare = () => {
          router.push({ path: "/signin" });
     };

     const onKYC = () => {
          const { validateEveryAction } = useKYC();
          validateEveryAction("referral");
     };

     const vipTiers = () => {
          const el = document.getElementById("promotion-moozi-loyalty-program");

          if (el) {
               const tabs = document.querySelectorAll(".loyalty-program-tab");

               for (let index = 0; index < tabs.length; index++) {
                    tabs[index].addEventListener("click", () => {
                         const currentActiveButton = document.querySelector(
                              ".loyalty-program-tab.active"
                         );
                         const currentActivePanel = document.querySelector(
                              ".loyalty-program-panel.active"
                         );

                         const newActivePanel = document.querySelector(
                              "#" +
                                   tabs[index].getAttribute("data-relay") +
                                   "-panel"
                         );

                         // Remove the 'active' class from the currently active button, if any
                         if (currentActiveButton) {
                              currentActiveButton.classList.remove("active");
                         }

                         if (currentActivePanel) {
                              currentActivePanel.classList.remove("active");
                         }

                         // Add the 'active' class to the new button
                         tabs[index].classList.add("active");
                         newActivePanel.classList.add("active");
                    });
               }
          }
     };

     return {
          referralBonus,
          vipTiers
     };
};
